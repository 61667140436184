<template>
  <v-container fluid>
    <div class="px-5">
      <v-row>
        <v-col cols="12" md="8">
          <v-text-field
            class="px-2"
            v-model="search"
            prepend-inner-icon="search"
            label="Search Businesses"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-checkbox label="Show joined businesses only" v-model="showMyBusinessesOnly"></v-checkbox>
        </v-col>
      </v-row>
      <v-row v-if="pageLoading === false">
        <v-col
          cols="12"
          md="3"
          class="text-center"
          v-for="business in filteredBusinesses"
          :key="business.id"
        >
          <v-card class="mx-2">
            <v-icon class="py-8" size="60">business</v-icon>
          </v-card>

          <p class="font-weight-medium mt-1 body-2 text-capitalize mb-0">{{business.name}}</p>
          <v-btn
            v-if="isSuperUser || isUnoappUser"
            text
            small
            color="grey darken-2"
            class="font-weight-bold text-capitalize"
            @click="$router.push({name: 'Edit Business', params: {id: business.id}})"
          >
            <!-- <v-icon small left>edit</v-icon> -->
            Apps
          </v-btn>|
          <v-btn
            v-if="showJoinButton(business)"
            text
            small
            color="primary"
            class="font-weight-bold text-capitalize"
            @click="join(business)"
          >
            <!-- <v-icon small left>edit</v-icon> -->
            Join
          </v-btn>
          <v-btn
            v-else
            text
            small
            color="primary"
            class="font-weight-bold text-capitalize"
            disabled
          >
            <!-- <v-icon small left>edit</v-icon> -->
            Joined
          </v-btn>|
          <v-btn
            v-if="isSuperUser"
            text
            small
            color="error"
            class="font-weight-bold text-capitalize"
            @click="deleteBusiness.selected = business, deleteBusiness.modal = true"
          >Delete</v-btn>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" md="3" class="text-center" v-for="x in 10" :key="x">
          <v-skeleton-loader :loading="pageLoading" type="image" :transition="'slide-y-transition'"></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <!-- Create New App Icon -->
    <v-tooltip left>
      <template v-slot:activator="{ on }">
        <v-btn
          @click="createNew.dialog = true"
          fab
          fixed
          dark
          bottom
          right
          color="primary"
          v-on="on"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </template>
      <span>Create Business</span>
    </v-tooltip>

    <v-dialog v-model="createNew.dialog" width="600" persistent transition="dialog-transition">
      <v-card>
        <v-card-title>
          <v-row>
            <v-col class="pa-0" cols="auto">
              <v-icon color="darkGrey--text text-lighten-1" class="mt-2 mr-3">business</v-icon>
            </v-col>
            <v-col class="pa-0" cols="auto">Create a business</v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-text-field
              v-model="createNew.name"
              flat
              name="Business Name"
              label="Name"
              :rules="rules"
              required
            ></v-text-field>
            <v-text-field v-model="createNew.slug" flat name="Slug" label="Slug"></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="createNew.dialog = false" color="grey" text class="text-capitalize">Cancel</v-btn>
          <v-btn
            @click="createBusiness"
            elevation="0"
            color="primary"
            class="text-capitalize"
          >Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="branding.dialog" v-if="branding.dialog" :width="850" :scrollable="true">
      <v-card class="px-0">
        <v-card-title>
          <p class="font-weight-medium title darkGrey--text">Branding</p>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="4">
              <v-card height="100%">
                <v-card-title primary-title>Logo</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-layout row wrap justify-center>
                    <v-hover v-slot:default="{ hover }">
                      <v-img
                        aspect-ratio="1.6"
                        @click="openResource"
                        class="grey lighten-3"
                        :class="{'lighten-2':hover}"
                        :src="branding.logo_url"
                      ></v-img>
                    </v-hover>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="8">
              <v-card>
                <v-card-title primary-title>Colors</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-form ref="brandingForm">
                    <v-row class="align-baseline">
                      <v-col class="pb-0" cols="12" md="6">
                        <span
                          class="subtitle-1 font-weight-medium black--text text--lighten-3"
                        >Primary Color</span>
                      </v-col>
                      <v-col class="pb-0" cols="12" md="6">
                        <v-text-field
                          v-model="branding.primary_color"
                          readonly
                          @click="menu.primary = true"
                          hide-details
                          class="ma-0 pa-0"
                          outlined
                          :rules="rules"
                          required
                        >
                          <template v-slot:prepend-inner>
                            <v-menu
                              v-model="menu.primary"
                              top
                              nudge-bottom="105"
                              nudge-left="16"
                              :close-on-content-click="false"
                            >
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  left
                                  size="20"
                                  :color="branding.primary_color"
                                  v-on="on"
                                >fas fa-palette</v-icon>
                              </template>
                              <v-card>
                                <v-card-text class="pa-0">
                                  <v-color-picker v-model="branding.primary_color" flat />
                                </v-card-text>
                              </v-card>
                            </v-menu>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="align-baseline">
                      <v-col class="pb-0" cols="12" md="6">
                        <span
                          class="subtitle-1 font-weight-medium black--text text--lighten-3"
                        >Secondary Color</span>
                      </v-col>
                      <v-col class="pb-0" cols="12" md="6">
                        <v-text-field
                          v-model="branding.secondary_color"
                          @click="menu.secondary = true"
                          readonly
                          hide-details
                          class="ma-0 pa-0"
                          outlined
                          :rules="rules"
                          required
                        >
                          <template v-slot:prepend-inner>
                            <v-menu
                              v-model="menu.secondary"
                              top
                              nudge-bottom="105"
                              nudge-left="16"
                              :close-on-content-click="false"
                            >
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  left
                                  size="20"
                                  :color="branding.secondary_color"
                                  v-on="on"
                                >fas fa-palette</v-icon>
                              </template>
                              <v-card>
                                <v-card-text class="pa-0">
                                  <v-color-picker v-model="branding.secondary_color" flat />
                                </v-card-text>
                              </v-card>
                            </v-menu>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="align-baseline">
                      <v-col class="pb-0" cols="12" md="6">
                        <span
                          class="subtitle-1 font-weight-medium black--text text--lighten-3"
                        >Accent Color</span>
                      </v-col>
                      <v-col class="pb-0" cols="12" md="6">
                        <v-text-field
                          @click="menu.accent = true"
                          v-model="branding.accent_color"
                          readonly
                          hide-details
                          class="ma-0 pa-0"
                          outlined
                          :rules="rules"
                          required
                        >
                          <template v-slot:prepend-inner>
                            <v-menu
                              v-model="menu.accent"
                              top
                              nudge-bottom="105"
                              nudge-left="16"
                              :close-on-content-click="false"
                            >
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  left
                                  size="20"
                                  :color="branding.accent_color"
                                  v-on="on"
                                >fas fa-palette</v-icon>
                              </template>
                              <v-card>
                                <v-card-text class="pa-0">
                                  <v-color-picker v-model="branding.accent_color" flat />
                                </v-card-text>
                              </v-card>
                            </v-menu>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="addBranding" color="primary" class="text-capitalize">Save Changes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      :value="deleteBusiness.modal"
      persistent
      max-width="650px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title>Are you sure you want to delete the business?</v-card-title>
        <v-card-text>
          <p>
            <span class="font-weight-bold">WARNING!</span> This action is not reversible. Please be sure that you really don't need this business anymore.
          </p>
          <v-text-field
            v-model="deleteBusiness.confirm"
            label="Enter 'DELETE' to delete this business."
          ></v-text-field>
          <v-checkbox
            label="I agree this will DELETE all entities and apps associaited with my business and will DELETE the business. This action is not reversible."
            v-model="deleteBusiness.agree"
          ></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!enableConfirmDeleteBusinessButton"
            @click="confirmDeleteBusiness"
            elevation="0"
            color="error"
          >Confirm</v-btn>
          <v-btn
            @click="deleteBusiness.modal = false"
            color="darkGrey"
            text
            class="text-capitalize"
          >Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { AuthAPI } from '../../clients/auth/auth.api'
import { mapActions, mapGetters } from 'vuex'
import { EventBus } from '@/event-bus'

export default {
  data() {
    return {
      pageLoading: false,
      search: '',
      businesses: [],
      createNew: {
        dialog: false,
        name: '',
        slug: '',
        id: ''
      },

      branding: {
        dialog: false,
        id: '',
        logo_url: '',
        primary_color:'#c9c9c9',
        secondary_color: '#c9c9c9',
        accent_color: '#c9c9c9'
      },
      menu: {
        primary: false,
        secondary: false,
        accent: false
      },
      rules: [v => !!v || 'Required.'],
      showMyBusinessesOnly: false,
      deleteBusiness: {
        selected: null,
        agree: false,
        confirm: '',
        modal: false
      }
    }
  },
  components: {
  },
  computed: {
    ...mapGetters({
      vg_activeBusiness: 'auth/activeBusiness',
      vg_activeBusinessBrandingAndLogo: 'auth/activeBusinessBrandingAndLogo',
      vg_businessesList: 'auth/businessesList',
      vg_user: 'auth/user'
    }),
    filteredBusinesses() {
      if (this.showMyBusinessesOnly) {
        if (this.search) {
          return this.vg_businessesList.filter(business =>
            business.name.toLowerCase().match(this.search.toLowerCase())
          )
        }
        return this.vg_businessesList
      } else {
        if (this.search) {
          return this.this.businesses.filter(business =>
            business.name.toLowerCase().match(this.search.toLowerCase())
          )
        }
      }
      return this.businesses
    },
    isSuperUser() {
      return this.vg_user.type === 'super'
    },
    isUnoappUser() {
      return this.vg_user.type === 'unoapp'
    },
    enableConfirmDeleteBusinessButton() {
      return this.deleteBusiness.confirm === 'DELETE' && this.deleteBusiness.agree === true
    }
  },
  async mounted() {
    this.$root.$on('resource-selected', (event) => {
      this.branding.logo_url = event[0].full_url
    })
    this.getAllBusinesses()
  },
  methods: {
    ...mapActions({
      va_setBusinessesList: 'auth/setUserBusinessesList',
      va_setActiveBusiness: 'auth/setActiveBusiness',
      va_setActiveBusinessBranding: 'auth/setActiveBusinessBranding'
    }),
    openResource() {
      this.openResourcePicker()
    },
    showJoinButton(business) {
      let businessInList = this.vg_businessesList.find(i => i.id === business.id)
      return !businessInList
    },
    async getAllBusinesses() {
      this.pageLoading = true
      let response
      try {
        response = await AuthAPI.getAllBusinesses()
      } catch (error) {
        this.pageLoading = false
        return console.log(error)
      }
      this.businesses = response.data.payload.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      this.pageLoading = false
    },
    async createBusiness() {
      let response
      if (this.$refs.form.validate()) {
        const data = {
          name: this.createNew.name,
          slug: this.createNew.slug
        }
        try {
          response = await AuthAPI.createBusiness(data)
        } catch (error) {
          return console.log(error)
        }
        this.businesses.push(response.data.payload)
        this.branding.id = response.data.payload.id
        this.createNew.dialog = false
        this.branding.dialog = true
      }
    },
    async addBranding() {
      this.spinner(true)
      if (this.$refs.brandingForm.validate()) {
        let data = {
          primary_color: this.branding.primary_color,
          secondary_color: this.branding.secondary_color,
          accent_color: this.branding.accent_color,
          logo_url: this.branding.logo_url
        }
        let response
        try {
          response = await AuthAPI.updateBusinessBranding(
            this.branding.id,
            data
          )
        } catch (error) {
          this.spinner(false)
          return console.log(error)
        }
        this.branding.dialog = false
      } else {
        this.snackbar({ text: 'Please check if all color values are selected.' })
      }
      this.reset()
      this.spinner(false)
    },
    reset() {
      this.createNew = {
        dialog: false,
        name: '',
        slug: '',
        id: ''
      }

      this.branding = {
        dialog: false,
        id: '',
        logo_url: '',
        primary_color: '',
        secondary_color: '',
        accent_color: ''
      }
    },
    async join(business) {
      this.spinner(true)
      let response
      try {
        response = await AuthAPI.joinBusiness(business.id)
      } catch (error) {
        this.spinner(false)
        return console.log(error)
      }
      await this.va_setBusinessesList()
      this.spinner(false)
      this.switchBusiness(business)
    },
    async switchBusiness(business) {
      this.spinner(true)
      try {
        await this.va_setActiveBusiness(business)
      } catch (error) {
        this.spinner(false)
        return
      }
      try {
        await this.va_setActiveBusinessBranding(business.id)
      } catch (error) {
        this.spinner(false)
        return console.log(error)
      }
      this.$vuetify.theme.themes.light.primary = this.vg_activeBusinessBrandingAndLogo.primary
      this.$vuetify.theme.themes.light.secondary = this.vg_activeBusinessBrandingAndLogo.secondary
      this.$vuetify.theme.themes.light.accent = this.vg_activeBusinessBrandingAndLogo.accent

      if (this.$route.name !== 'Applications') {
        this.$router.push({ name: 'Applications' })
      }
      console.log(this.$route)
      EventBus.$emit('fetch-mounting-methods')
      this.spinner(false)
      this.swal({
        title: 'Success',
        text: 'You have successfully joined the business.',
        type: 'success'
      })
    },
    async confirmDeleteBusiness() {
      let response
      try {
        await AuthAPI.deleteBusiness(this.deleteBusiness.selected.id)
      } catch (error) {
        return console.log(error)
      }
    }
  }
}
</script>

<style>
</style>
